import { defaultContext } from '../../context/session/SessionContext'

export function retrieveSession() {
    try {
        const session = JSON.parse(localStorage.getItem('session'));
        return session || defaultContext;
    } catch {
        return defaultContext;
    }
}

export function storeSession(session) {
    try {
        localStorage.setItem('session', JSON.stringify(session))
    } catch {

    }
}