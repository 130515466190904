import React from 'react';
import { HTMLTable, Button, Popover, Menu, MenuDivider, Position, MenuItem } from '@blueprintjs/core';
import { useLocation, Link } from 'react-router-dom';
import QueryParam from '../../services/queryparam';
import './PaginatedTable.css';

export default function PaginatedTable({paginatedResults, children, className, filter, sort, ...props}) {
    const { pathname, search } = useLocation();

    const makeSearch = (movement) => { const nQp = new QueryParam("nPerPage", paginatedResults.nPerPage); const pQp = new QueryParam("page", paginatedResults.page + movement); return nQp.addTo(pQp.addTo(search)); };
    const paginationRow = () => {
        return (
            <div className="pagination-row">
                <p>{paginatedResults.hasPrev
                        ? <Link to={{pathname, search: makeSearch(-1)}}>Prev</Link>
                        : "Prev"
                    }</p>
                <p>{paginatedResults.cursorStart + " to " + paginatedResults.cursorEnd + " of " + paginatedResults.total}</p>
                <p>{"page " + paginatedResults.page + " of " + paginatedResults.pages}</p>
                <p>{paginatedResults.hasNext
                        ? <Link to={{pathname, search: makeSearch(1)}}>Next</Link>
                        : "Next"
                    }</p>
                {filter && <Filter filter={filter} search={search} pathname={pathname}/>}
                {sort && <SortBy sort={sort} search={search} pathname={pathname}/>}
            </div>
        )
    }

    return (<div>
        {paginationRow()} 
        <HTMLTable {...props} style={{width:'100%'}}>
            {children}
        </HTMLTable>
        {paginationRow()} 
    </div>)
}

function Filter({filter, search, pathname}) {
    return (
        <Popover position={Position.TOP}>
            <Button text="Filter" />
            <Menu>
                {filter.map(([label, key, type, options]) => {
                    return (
                        <>
                            <MenuDivider title={label} />
                                {
                                    options.map(([label,value])=> {
                                        const queryparam = new QueryParam(key, value);
                                        const active = queryparam.isActiveIn(search);
                                        const hrefSearch = active
                                            ? queryparam.removeFrom(search) // unselect if selected
                                            : queryparam.addTo(search) // select if unselected
                                        return (<MenuItem active={active} text={label} href={pathname + hrefSearch}/>);
                                    })
                                }
                        </>
                    ) 
                })}
            </Menu>

        </Popover>
    );
}

function SortBy({sort, pathname, search}) {
    return (
        <Popover position={Position.TOP}>
            <Button text="Sort by.."/>
            <Menu>
                {sort.map(option => {
                    const [sortKey, sortLabel] = option;
                    const queryparam = new QueryParam("sort", sortKey);
                    const active = queryparam.isActiveIn(search);
                    const hrefSearch = queryparam.addTo(search);
                    return <MenuItem text={sortLabel} href={pathname + hrefSearch} key={sortKey} active={active} />
                })}
            </Menu>
        </Popover>
    );
}