import React, { useEffect, useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import guruserver from '../../../network/guruserver'
import {SessionContext} from '../../../context/session/SessionContext';
import {Card, Checkbox, Icon, Switch, Button, Label, NumericInput, Intent, HTMLTable } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {useBackLink} from '../../../hooks/restorableLinks';
import toUSD from '../../../services/toUSD';
import './BrandPage.css';

export default function BrandPage() {
    const { id } = useParams();
    const session = useContext(SessionContext)
    const [profile, setProfile] = useState({})
    const [error, setError] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [editLock, setEditLock] = useState(true);
    const [localDiscount, setLocalDiscount] = useState(null);
    const [localMaxRebate, setLocalMaxRebate] = useState(null);
    const backLink = useBackLink();

    useEffect(() => {
        const req = guruserver
            .authorize(session)
            .profile(id)
            .get();
        
        req.promise()
            .then(setProfile)
            .catch(setError)
            .finally(()=>setIsLoading(false));

        return function cleanup() {
            req.cancel();
        }
    }, [id, session]);

    if (isLoading) {
        return "Loading..."
    } else if (error) {
        return error.message
    } else {
        let { 
            company,
            url,
            minstagram: {
                username: handle
            },
            category,
            name,
            email,
            active,
            scriptTagPages,
            chargeStatus,
            discount,
            maxRebate,
            refunds,
            fees,
            reach,
            cpv
        } = profile;

        discount = Math.round(100 * discount); // decimal to %
        maxRebate = Math.round(maxRebate / 100); // pennies to $

        let chargeStatusWarning;
        let chargeStatusIconIntent;
        let ftBtnDisabled = true;
        let hasFreeTrial = false;
        switch (chargeStatus) {
            case "Free Trial":
                chargeStatusWarning = <p>This store is on a free trial.</p>
                chargeStatusIconIntent = Intent.DANGER;
                ftBtnDisabled = false;
                hasFreeTrial = true;
                break;
            case "Pending":
                chargeStatusWarning = active
                    ? <p><Icon icon="warning-sign" intent="danger"/>This store has not accepted usage fees.</p>
                    : <p><Icon icon="warning-sign" intent="warning"/>This store must accept usage fees before enabling widget.</p>
                chargeStatusIconIntent = Intent.WARNING;
                break;
            case "Activated":
                chargeStatusIconIntent = Intent.SUCCESS;
                ftBtnDisabled = false;
                break;
            default:
                break;
        }

        const chargeStatusIcon = <Icon icon={IconNames.FULL_CIRCLE} intent={chargeStatusIconIntent} iconSize={12} />
        const ftBtn = (<Button onClick={()=>{
            const confirmationText = hasFreeTrial
                ? "Are you sure you want to end " + company + "'s free trial?"
                : "Are you sure you want to give " + company + " a free trial?";
            if (window.confirm(confirmationText)) {
                updateProfile({exemptFromCharge: !hasFreeTrial, chargeStatus: hasFreeTrial ? "Activated": "Free Trial"})
            }
        }} disabled={ftBtnDisabled || isUpdating} style={{marginTop:'12px'}} minimal outlined>
            {hasFreeTrial ? "Revoke Free Trial" : "Start Free Trial"}
        </Button>);

        const widgetIcon = editLock ? "lock" : "unlock";
        const widgetLockTitle = "Click to enable editing this store's widget settings."

        const pluginBlocked = chargeStatus !== "Activated" && !active;
        const pluginDisabled = editLock || pluginBlocked || isUpdating;
        const pagesDisabled = editLock || pluginBlocked || !active || isUpdating;
        const canSaveRebate = !pluginDisabled
            && (
                (localMaxRebate != null && localMaxRebate !== maxRebate)
                ||
                (localDiscount != null && localDiscount !== discount)
            );

        const iglink = "https://instagram.com/" + handle

        const widgetIconIntent = active ? "primary" : "none";

        const dealsLink = `/deals?sort=-createdAt&brand=${id}`
        
        const mailLink = <a href={`mailto:${email}`}>{email}</a>

        const clickLock = (e) => {
            e.preventDefault();
            if (editLock && !window.confirm("Begin editing store's widget settings?")) {
                return;
            }
            setEditLock(!editLock);
        }

        const updateProfile = async (update) => {
            setIsUpdating(true);
            setProfile({
                ...profile,
                ...update
            });
            try {
                await guruserver
                    .authorize(session)
                    .profile(id)
                    .put(update)
                    .promise()
            } catch (err) {
                alert("Error: " + err.message);
                setProfile(profile);
            } finally {
                setIsUpdating(false);
            }
        }

        // a separate endpoint, as updating the widget requires updating data on Shopify as well
        const updateWidget = async (active, scriptTagPages) => {
            setIsUpdating(true);
            setProfile({
                ...profile,
                active,
                scriptTagPages
            })
            try {
                await guruserver
                    .authorize(session)
                    .profile(id)
                    .updateScriptTags(active, scriptTagPages)
                    .promise()
            } catch (err) {
                alert("Error: " + err.message);
                setProfile(profile);
            } finally {
                setIsUpdating(false);
            }
        }

        const toggleWidget = () => {
            updateWidget(!profile.active, profile.scriptTagPages)
        }

        const togglePage = (page) => {
            const updatedPages = Array.from(scriptTagPages)
            let idx = updatedPages.findIndex(p=>p===page);
            if (idx >= 0) {
                updatedPages.splice(idx, 1);
            } else {
                updatedPages.push(page)
            }
            updateWidget(profile.active, updatedPages)
        }

        const onRebateSave = (e) => {
            e.preventDefault();

            let updatedDiscount = localDiscount === null
                ? discount / 100.0 // % to decimal
                : localDiscount / 100.0;
            let updatedMaxRebate = localMaxRebate === null
                ? maxRebate * 100.0 // $ to pennies
                : localMaxRebate * 100.0
            updateProfile({
                discount: updatedDiscount,
                maxRebate: updatedMaxRebate
            })
        }

        return (
            <>
                {backLink}
                <div style={{display:'flex', margin: '12px', maxWidth: '900px', justifyContent:'space-between', flexWrap: 'wrap'}}>
                    <Card className="widget-info" elevation={2} style={{width: '240px'}}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <Button icon={widgetIcon} onClick={clickLock} disabled={pluginBlocked} htmlTitle={widgetLockTitle} title={widgetLockTitle} />
                            <h4 style={{display: "inline", marginLeft:'8px'}}>Widget Settings</h4>
                        </div>
                        {chargeStatusWarning}
                        <div style={{margin: '15px 0'}}>
                            <Switch onChange={toggleWidget} checked={profile.active} disabled={pluginDisabled} labelElement={<Icon intent={widgetIconIntent} icon={IconNames.CELL_TOWER}/>}/>
                            <h5 style={{marginTop: '0'}}>Plugin is {active ? "enabled." : "disabled."}</h5>
                        </div>
                        <div>
                            <h5>Pages</h5>
                            <Checkbox disabled={true} onChange={() => { togglePage("order") }} checked={active && scriptTagPages.includes("order")}>Order Status</Checkbox>
                            <Checkbox disabled={pagesDisabled} onChange={() => { togglePage("product") }} checked={active && profile.scriptTagPages.includes("product")}>Product</Checkbox>
                            <Checkbox disabled={pagesDisabled} onChange={() => { togglePage("cart") }} checked={active && profile.scriptTagPages.includes("cart")}>Cart</Checkbox>
                        </div>
                        <Card elevation={1} style={{width: '200px'}}>
                            <form onSubmit={onRebateSave}>
                                <h4>Rebate</h4>
                                <Label>
                                    Cashback (%)
                                    <NumericInput intent={Intent.PRIMARY} style={{width: '75px'}} leftIcon="percentage" disabled={pluginDisabled} value={discount} min={0} max={100} onValueChange={setLocalDiscount}/>
                                </Label>
                                <Label>
                                    Max Rebate ($) ($0 means "no limit")
                                    <NumericInput intent={Intent.PRIMARY} style={{width: '75px'}} leftIcon="dollar" disabled={pluginDisabled} value={maxRebate} min={0} onValueChange={setLocalMaxRebate}/>
                                </Label>
                                <Button disabled={!canSaveRebate} type="submit" intent={Intent.PRIMARY} text="Save" />
                            </form>
                        </Card>
                    </Card>
                    <Card elevation={2} style={{width:'200px', height: '200px'}}>
                        <div>
                            <h5>Charge Status</h5>
                            <div style={{display:'flex',alignItems:'center'}}>
                                {chargeStatusIcon}
                                <span style={{marginLeft:'6px'}}>
                                    {chargeStatus}
                                </span>
                            </div>
                            {ftBtn}
                        </div>
                    </Card>
                    <Card elevation={2} className="brand-info">
                        <h5>{company}</h5>
                        <p><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></p>
                        <p><a href={iglink} target="_blank" rel="noopener noreferrer">@{handle}</a></p>
                        <p>{name}</p>
                        <p>{mailLink}</p>
                        <p>{category}</p>
                        <Link to={dealsLink}>View deals</Link>
                    </Card>
                    <HTMLTable id="cpv-table">
                        <thead>
                            <tr>
                                <th />
                                <th>Est. Reach</th>
                                <th>Cost (Refunds)</th>
                                <th>Cost (Fees)</th>
                                <th>Est. CPV</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>This Week</td>
                                <td>{reach.last_week.toLocaleString('en')}</td>
                                <td>{toUSD(refunds.last_week)}</td>
                                <td>{toUSD(fees.last_week)}</td>
                                <td>{toUSD(cpv.last_week, 4)}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{reach.total.toLocaleString('en')}</td>
                                <td>{toUSD(refunds.total)}</td>
                                <td>{toUSD(fees.total)}</td>
                                <td>{toUSD(cpv.total, 4)}</td>
                            </tr>
                        </tbody>
                    </HTMLTable>
                </div>
            </>
        )
    }
}
