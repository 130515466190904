import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink
} from "react-router-dom";
import PrivateContent from './components/privatecontent/PrivateContent'
import { retrieveSession, storeSession } from './services/initialsession/InitialSession';
import { SessionContext, defaultContext } from './context/session/SessionContext'
import DealsPage from './pages/deals/DealsPage';
import LoginPage from './pages/login/Login';
import StoriesPage from './pages/stories/StoriesPage'
import ScraperPage from './pages/scraper/ScraperPage';
import GurusPage from './pages/users/gurus/GurusPage';
import BrandsPage from './pages/users/brands/BrandsPage';
import BrandPage from './pages/users/brand/BrandPage';
import GuruPage from './pages/users/guru/GuruPage';
import HomePage from './pages/home/HomeStats';
import './App.css';
import { useMemo } from 'react';
import { Button, Intent, Navbar, Alignment } from '@blueprintjs/core';
import DealPage from './pages/deals/deal/DealPage';
import logo from './assets/GuruClub_logo__Wordmark.svg';

function App() {
  const [session, setSession] = useState(retrieveSession())

  useEffect(() => {
    storeSession(session)
  }, [session])

  const logout = useMemo(() => function() {
    setSession(defaultContext)
  }, []);

  return (
    <SessionContext.Provider value={session}>
      <Router>
        <div>
          <Switch>
            <Route path="/login">
              <LoginPage login={setSession}/>
            </Route>
            <Route>
              <PrivateContent redirect="/login" isAuthenticated={session.isAuthenticated} >
                <Navbar>
                  <Navbar.Group align={Alignment.LEFT}>
                    <img src={logo} alt="guru club" style={{height:'36px'}} />
                    <Navbar.Divider />
                    <Navbar.Heading>Dashboard</Navbar.Heading>
                  </Navbar.Group>
                  <Navbar.Group align={Alignment.CENTER}>
                    <NavBarLink to="/" exact>Home</NavBarLink>
                    <NavBarLink to="/users/gurus" >Gurus</NavBarLink>
                    <NavBarLink to="/users/brands">Brands</NavBarLink>
                    <NavBarLink to="/stories">Stories</NavBarLink>
                    <NavBarLink to="/scraper">Scraper</NavBarLink>
                    <NavBarLink to="/deals">Deals</NavBarLink>
                  </Navbar.Group>
                  <Navbar.Group align={Alignment.RIGHT}>
                    <Button intent={Intent.DANGER} text="Logout" outlined minimal onClick={logout} />
                  </Navbar.Group>
                </Navbar>
                <Switch>
                  <Route exact path="/">
                    <HomePage />
                  </Route>
                  <Route exact path="/users/gurus">
                    <GurusPage />
                  </Route>
                  <Route path="/users/gurus/:id">
                    <GuruPage />
                  </Route>
                  <Route exact path="/users/brands">
                    <BrandsPage />
                  </Route>
                  <Route path="/users/brands/:id">
                    <BrandPage />
                  </Route>
                  <Route path="/stories">
                    <StoriesPage />
                  </Route>
                  <Route path="/scraper">
                    <ScraperPage />
                  </Route>
                  <Route exact path="/deals">
                    <DealsPage />
                  </Route>
                  <Route path="/deals/:id">
                    <DealPage />
                  </Route>
                  <Route>
                    Page not found.
                  </Route>
                </Switch>
              </PrivateContent>
            </Route>
          </Switch>
        </div>
      </Router>
    </SessionContext.Provider>
  );
}

function NavBarLink(props) {
  return <NavLink className="nav-bar-link" activeClassName="nav-bar-link active" {...props}>{props.children}</NavLink>
}

export default App;
