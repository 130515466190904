import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import toUSD from '../../services/toUSD';
import moment from 'moment';
import './StoryCard.css';
import { Card } from '@blueprintjs/core';

export default function StoryCard({deal}) {
    const content = deal.content[deal.content.length - 1];
    const imageSrc = content && content.thumbnailUrl
    const contentType = content && content.contentType;
    const { _id: guruid, igHandle: guruhandle } = deal.influencer;
    const { _id: brandid, company } = deal.brand;
    const { productName, _id: dealid } = deal;
    const { taken_at_timestamp } = deal.story;

    const mediaLinkRow = contentType && (contentType === "image"
        ? <div style={{marginBottom:'12px'}}><a href={content.sourceUrl} target="blank" rel="noopener noreferrer">Image</a></div>
        : <div style={{marginBottom:'12px'}}><a href={content.thumbnailUrl} target="blank" rel="noopener noreferrer">Thumbnail</a><a style={{marginLeft:'12px'}} href={content.sourceUrl} target="blank" rel="noopener noreferrer">Video</a></div>)
    return (
        <Card elevation={1} className="story-card">
            <img src={imageSrc} alt="" />
            <div className="story-card-info-block">
                {mediaLinkRow}
                {toUSD(deal.rebate)}
                <p className="story-card-label">Cashback</p>
                <Link to={"/users/gurus/"+guruid}>{guruhandle}</Link>
                <p className="story-card-label">Guru</p>
                <Link to={"/users/brands/"+brandid}>{company}</Link>
                <p className="story-card-label">Brand</p>
                <p>{productName}</p>
                <p className="story-card-label">Product</p>
                {moment(taken_at_timestamp).format('L')}
                <p className="story-card-label">Posted</p>
                <Link to={"/deals/"+dealid}>View Deal</Link>
            </div>
        </Card>
    )
}

StoryCard.propTypes = {
    deal: PropTypes.shape({
        story: PropTypes.object.isRequired,
        influencer: PropTypes.object.isRequired,
        brand: PropTypes.object.isRequired,
        _id: PropTypes.string.isRequired,
        content: PropTypes.array.isRequired
    })
}