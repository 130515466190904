import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { SessionContext } from '../../context/session/SessionContext';
import guruserver from '../../network/guruserver';
import encrypt from '../../services/encrypt'

export default function LoginPage(props) {

    const { login } = props;
    const { 
        isAuthenticated
    } = useContext(SessionContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    
    const history = useHistory();

    const onSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setErrorMsg(null)

            const unencrypted = Buffer.from(JSON.stringify({user: email, password, device: { identifier: 'admin_console'}}))
            const jweString = await encrypt(unencrypted);

            const {
                _id,
                token,
                admin
            } = await guruserver.login(jweString).promise();

            if (admin) {
                login({
                    isAuthenticated: true,
                    authToken: token,
                    userID: _id,
                    isAdmin: admin
                })
            } else {
                throw new Error("This is not an admin account!");
            }

            history.push({ pathname: "/" })
        } catch (err) {
            setIsLoading(false);
            setErrorMsg(err.message);
        }
    }

    if (isAuthenticated) {
        return "You are logged in already."
    } else {
        return (
            <div>
                <h2>Login</h2>
                <form onSubmit={onSubmit}>
                    <input placeholder="Email" type="email" value={email} onChange={e=>setEmail(e.target.value)} disabled={isLoading}/>
                    <input placeholder="Password" type="password" value={password} onChange={e=>setPassword(e.target.value)} disabled={isLoading}/>
                    <button type="submit" disabled={isLoading}>Submit</button>
                    {errorMsg}
                </form>
            </div>
        )
    }
}