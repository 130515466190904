import React from 'react';
import { Chart } from 'react-charts';

export default function DealsChart({metrics, ...props}) {
    const data = React.useMemo(
        () => [
          {
            label: 'Deals Started',
            data: metrics.map(dayStats=>[
                new Date(dayStats.day_start), dayStats.created_deals.total
            ])
          },
          {
            label: 'Refunds',
            data: metrics.map(dayStats=>[
                new Date(dayStats.day_start), dayStats.completed_deals.total
            ])
          }
        ],
        [metrics]
      )
    
    const axes = React.useMemo(
        () => [
            { primary: true, type: 'time', position: 'bottom' },
            { type: 'linear', position: 'left' },
        ],
        []
    )
    return (
        <div {...props}>
            <h3>Deals</h3>
            <div
                style={{
                    width: '600px',
                    height: '400px'
                }}
            >
                <Chart
                    data={data}
                    axes={axes}
                    tooltip
                />
            </div>
        </div>
    );
}