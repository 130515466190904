import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

/**
 * Returns a ref callback,
 * if window.location.state.rowToRestore is set and the node
 * using ref callback has a child at that index,
 * the window will scroll to that child upon setting the ref.
 */
export function useRowRestore() {
    const { state } = useLocation();
    const refCallback = useCallback(node => {
        if (node !== null && state && state.rowToRestore && node.children.length > state.rowToRestore) {
            const row = node.children[state.rowToRestore];
            window.scrollTo(row.offsetLeft, row.offsetTop)
        }
    }, [state])
    return refCallback;
}

/**
 * if window.location.state has data from RestorableLink,
 * returns a Link that navigates back to it. Else returns null.
 */
export function useBackLink() {
    const { state } = useLocation();
    const backLink = state && state.from.data
        && <Link to={state.from.data}>Back to {state.from.name}</Link>
    return backLink || null;
}

/**
 * Generates a React Router Link to `options.pathname`,
 * with a `state.from` object which can be reused (as `to`) by another Link
 * to link back to the originating page
 * 
 * if `options.idx` is passed, useRowRestore on the originating page
 * will attempt to scroll the originating page back to the row containing the link
 * upon returning to the page
 * 
 * `options.name` is used by the returning link, e.g. name="gurus", link will say "Return to gurus"
 * 
 * @param {{pathname: String, name: String, idx: Number, ...props}} options 
 */
export function RestorableLink({pathname, name, idx, ...props}) {
    const location = useLocation();
    const state = {
        from: {
            data: {
                pathname:location.pathname,
                search: location.search,
                state: idx != null
                    ? {
                        rowToRestore: idx
                    }
                    : null
            },
            name
        }
    }

    return <Link {...props} to={{pathname, state}} />
}