import { useLocation, useHistory } from 'react-router-dom';

/**
 * If default search is provided, and the page loads with an empty search string
 * the page will redirect with the given default search string.
 * @param {string?} defaultSearch 
 */
export default function useDefaultQuery(defaultSearch) {
    const { search, pathname } = useLocation();
    const history = useHistory();

    if (defaultSearch && !search) {
        history.push({pathname, search: defaultSearch})
    }
}