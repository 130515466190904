import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../../context/session/SessionContext';
import guruserver from '../../network/guruserver'
import PaginatedTable from '../../components/paginatedtable/PaginatedTable';
import { Link, useLocation } from 'react-router-dom';
import './StoriesPage.css';
import dealIntent from '../../services/dealIntent';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export default function StoriesPage() {
    const session = useContext(SessionContext);
    const [paginatedRes, setPaginatedRes] = useState({
        results: [],
        cursorStart: 0,
        cursorEnd: 0,
        total: 0,
        page: 0,
        nPerPage: 0,
        pages: 0,
        hasNext: false,
        hasPrev: false
    })
    const [isLoading, setIsLoading] = useState(false);
    const { search } = useLocation();
    const [error, setError] = useState(null);

    useEffect(() => {
        const req = guruserver.authorize(session).stories()
        setIsLoading(true);
        req.search(search).promise()
            .then(setPaginatedRes)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session, search]);

    if (error) {
        return error.message;
    } else if (isLoading) {
        return "Loading..."
    } else return (
        <div>
            <h2>
                Gurus
            </h2>
            <PaginatedTable paginatedResults={paginatedRes}>
                <thead>
                    <th>Content</th>
                    <th>Status</th>
                    <th>Deal</th>
                    <th>Brand</th>
                    <th>Guru</th>
                </thead>
                <tbody>
                    {paginatedRes.results.map(StoryRow)}
                </tbody>
            </PaginatedTable>
        </div>
    )
}

function StoryRow(story) {
    let {
        ig_id,
        brand: {
            _id: brandid,
            company
        },
        guru: {
            _id: guruid,
            igHandle
        },
        content,
        deal,
        status
    } = story;

    content = content[content.length - 1];

    let iglink = status === "Pending"
        ? (<a
            href={`https://instagram.com/stories/${igHandle}/${ig_id}`}
            target="_blank"
            rel="noopener noreferrer"
        >view</a>)
        : null;
    return (
        <tr key={story._id} className="story-row">
            <td><a href={content.sourceUrl} target="_blank" rel="noopener noreferrer"><img src={content.thumbnailUrl} alt="" className="story-thumb" /></a></td>
            <td><Icon icon={IconNames.FULL_CIRCLE} intent={dealIntent({status})} size={12} />{iglink}</td>
            <td><Link to={`/deals/${deal}`}>Go to deal</Link></td>
            <td><Link to={`/users/brands/${brandid}`}>{company}</Link></td>
            <td><Link to={`/users/gurus/${guruid}`}>{igHandle}</Link></td>
        </tr>
    )
}