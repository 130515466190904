import moment from 'moment';

/**
 * 
 * @param {Date|String|Number} date 
 */
export default function dynamicDate(date) {
    return (Math.abs(new Date(date).getTime() - Date.now()) < 7 * 24 * 3600 * 1000
        ? moment(date).fromNow()
        : moment(date).calendar())
}