import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../../../context/session/SessionContext';
import guruserver from '../../../network/guruserver'
import PaginatedTable from '../../../components/paginatedtable/PaginatedTable';
import { useLocation } from 'react-router-dom';
import { useRowRestore, RestorableLink } from '../../../hooks/restorableLinks';
import useDefaultQuery from '../../../hooks/useDefaultQuery';
import './GurusPage.css';
import QueryParam from '../../../services/queryparam';
import dynamicDate from '../../../services/dynamicDate';

export default function GurusPage() {
    useDefaultQuery("?sort=-createdAt")
    const session = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(true);
    const scrollRef = useRowRestore();
    const { search } = useLocation();
    const [error, setError] = useState(null);
    const [paginatedRes, setPaginatedRes] = useState({
        results: [],
        cursorStart: 0,
        cursorEnd: 0,
        total: 0,
        page: 0,
        nPerPage: 0,
        pages: 0,
        hasNext: false,
        hasPrev: false
    })

    useEffect(() => {
        const filterQp = new QueryParam("filter", "guru");
        const req = guruserver.authorize(session).profiles();
        setIsLoading(true);
        req.search(filterQp.addTo(search)).promise()
            .then(setPaginatedRes)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session, search]);

    if (error) {
        return error.message;
    } else if (isLoading) {
        return "Loading..."
    } else return (
        <div>
            <h2>
                Gurus
            </h2>
            <PaginatedTable paginatedResults={paginatedRes} striped={true} sort={[["-createdAt","Newest"], ["createdAt", "Oldest"], ["minstagram.username","Handle"]]}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Joined</th>
                        <th style={{textAlign: 'right'}}>Followers</th>
                        <th style={{textAlign: 'right'}}>Following</th>
                        <th>Instagram</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody ref={scrollRef}>
                    {paginatedRes.results.map(GuruRow)}
                </tbody>
            </PaginatedTable>
        </div>
    );
}

function GuruRow(guru,idx) {
    const {
        minstagram: {
            username: handle,
            followers_count,
            follows_count
        },
        name,
        email,
        _id
    } = guru;

    const profileLink = '/users/gurus/' + _id
    const picUrl = 'https://dr2ucvwr1tnfi.cloudfront.net/'+_id;
    const igLink = 'https://instagram.com/' + handle;

    const mailLink = <a href={`mailto:${email}`}>{email}</a>

    return (
        <tr key={guru._id}>
            <td><img src={picUrl} alt="" className="pro-pic"/></td>
            <td><RestorableLink pathname={profileLink} idx={idx} name="gurus">{name || "{no name}"}</RestorableLink></td>
            <td>{dynamicDate(guru.createdAt)}</td>
            <td style={{textAlign:"right"}}>{followers_count ? followers_count.toLocaleString('en'): '--'}</td>
            <td style={{textAlign:"right"}}>{follows_count ? follows_count.toLocaleString('en') : '--'}</td>
            <td><a href={igLink} target="_blank" rel="noopener noreferrer">@{handle}</a></td>
            <td>{mailLink}</td>
        </tr>
    )
}
