import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { SessionContext } from '../../context/session/SessionContext'
import guruserver from '../../network/guruserver';
import StoryJobsTable from './storyjobs/StoryJobsTable';
import SignUpSessionsTable from './signupsessions/SignupSessionsTable';
import './ScraperPage.css';

export default function ScraperPage() {
    const session = useContext(SessionContext);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState({});

    useEffect(() => {
        const req = guruserver.authorize(session).scraper.status()
        setIsLoading(true);
        req.promise()
            .then(setStatus)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session]);


    if (isLoading) {
        return "Loading...";
    } else if (error) {
        return error.message;
    } else {

        let {
            health,
            signUpSessions,
            storyJobs: {
                actionRequired
            }
        } = status;

        return (
            <div>
                <h4>{health}</h4>
                <div>
                    <NavLink className="scraper-tab" activeClassName="active" to="/scraper/signup">Sign Ups ({signUpSessions})</NavLink>
                    <NavLink className="scraper-tab" activeClassName="active" to="/scraper/stories">Deals ({actionRequired})</NavLink>
                </div>
                <Switch>
                    <Route path="/scraper/signup">
                        <SignUpSessionsTable />
                    </Route>
                    <Route path="/scraper/stories">
                        <StoryJobsTable />
                    </Route>
                    <Redirect to="/scraper/signup" />
                </Switch>
            </div>
        )
    }
}
