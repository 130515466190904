import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../../context/session/SessionContext';
import guruserver from '../../network/guruserver'
import RevenueChart from '../../components/charts/RevenueChart';
import GurusChart from '../../components/charts/GurusChart';
import DealsChart from '../../components/charts/DealsChart';
import ActiveStoresChart from '../../components/charts/ActiveStoresChart';
import KPICard from '../../components/kpicard/KPICard';
import RecentlyClosed from '../../components/recentlyclosed/RecentlyClosed';


export default function HomeStats() {
    const session = useContext(SessionContext);
    const [stats, setStats] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const req = guruserver.authorize(session).dashboardstats();
        setIsLoading(true);
        req.promise()
            .then(setStats)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session]);

    const chartStyle = {display: 'block', margin: '50px auto', width: '600px'};

    if (error) {
        return error.message;
    } else if (isLoading) {
        return "Loading..."
    } else return stats.length && (
        <div>
            <HighlightsTable {...stats[0]} />
            <RecentlyClosed />
            <RevenueChart metrics={stats} style={chartStyle} />
            <GurusChart metrics={stats} style={chartStyle} />
            <ActiveStoresChart metrics={stats} style={chartStyle} />
            <DealsChart metrics={stats} style={chartStyle} />
        </div>
    );
}

function HighlightsTable(todaysStats) {
    const rightAlign= { textAlign: "right" }
    return (
        <div className="content-page">
            <h2>Highlights</h2>
            <KPICard>{[
                {
                    total: todaysStats.gurus.total,
                    label: "Gurus",
                    newToday: todaysStats.gurus.new
                },
                {
                    total: todaysStats.active_stores,
                    label: "Live Stores"
                },
                {
                    total: todaysStats.created_deals.total,
                    label: "Deals Started",
                    newToday: todaysStats.created_deals.new
                },
                {
                    total: todaysStats.completed_deals.total,
                    label: "Refunds",
                    newToday: todaysStats.completed_deals.new
                },
                {
                    total: todaysStats.refunds.total,
                    label: "Refunded",
                    newToday: todaysStats.refunds.new,
                    isMoney: true
                },
                // {
                //     total: todaysStats.revenue.total,
                //     label: "Revenue",
                //     newToday: todaysStats.revenue.new,
                //     isMoney: true
                // },
            ]}</KPICard>
        </div>
    );
}
