import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../../context/session/SessionContext';
import guruserver from '../../network/guruserver'
import PaginatedTable from '../../components/paginatedtable/PaginatedTable';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import toUSD from '../../services/toUSD';
import { Link, useLocation } from 'react-router-dom';
import useDefaultQuery from '../../hooks/useDefaultQuery';
import './DealsPage.css';
import { useRowRestore, RestorableLink } from '../../hooks/restorableLinks';
import dealIntent from '../../services/dealIntent';
import dynamicDate from '../../services/dynamicDate';

export default function DealsPage() {
    useDefaultQuery("?sort=-createdAt")
    const session = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { search } = useLocation();
    const scrollRef = useRowRestore();
    const [paginatedRes, setPaginatedRes] = useState({
        results: [],
        cursorStart: 0,
        cursorEnd: 0,
        total: 0,
        page: 0,
        nPerPage: 0,
        pages: 0,
        hasNext: false,
        hasPrev: false
    })

    useEffect(() => {
        setIsLoading(true);
        const req = guruserver.authorize(session).deals()
        req.search(search).promise()
            .then(setPaginatedRes)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session, search]);

    if (error) {
        return error.message;
    } else if (isLoading) {
        return "Loading..."
    } else return (
        <div>
            <h2>
                Deals
            </h2>
            <PaginatedTable paginatedResults={paginatedRes} striped={true} sort={[["-updatedAt","Updates"], ["-createdAt","New"]]}>
                <thead>
                    <tr>
                        <th colspan={2}>Status</th>
                        <th></th>
                        <th>Created</th>
                        <th>Closed</th>
                        <th style={{textAlign:'right'}}># Followers</th>
                        <th style={{textAlign:'right'}}>Est. Reach</th>
                        <th style={{textAlign:'right'}}>Refund</th>
                        <th style={{textAlign:'right'}}>Revenue</th>
                        <th colspan={2}>Guru</th>
                        <th>Brand</th>
                        <th style={{textAlign:'right'}}>Auto Tip</th>
                        <th style={{textAlign:'right'}}>Manual Tip</th>
                    </tr>
                </thead>
                <tbody ref={scrollRef}>
                {
                    paginatedRes.results.map(DealRow)
                }
                </tbody>
            </PaginatedTable>

        </div>
    )
}

function DealRow(deal, idx) {
    let {
        influencer: {
            _id: guru_id,
            igHandle: handle
        },
        price,
        rebate,
        productName,
        brand: {
            _id: brand_id,
            company
        },
        status,
        requested,
        _id,
        auto_tip,
        manual_tip,
        closed,
        expires,
        numFollowers,
        estimatedReach
    } = deal;

    auto_tip = auto_tip || 0;
    manual_tip = manual_tip || 0;

    const deallink = "/deals/" + _id

    const brandLink="/users/brands/" + brand_id
    const gurulink="/users/gurus/" + guru_id

    const gurupic = 'https://dr2ucvwr1tnfi.cloudfront.net/'+guru_id;
    const guruimg = <img src={gurupic} className="avatar" alt=""/>

    let statusIcon, intent = dealIntent(deal);
    if (intent) {
        statusIcon = <Icon iconSize={12} icon={IconNames.FULL_CIRCLE} intent={intent} />
    }

    const finished = closed || (status === "Expired" ? expires : null)

    return (
        <tr key={_id} className={"deal-row-"+status}>
            <td>{statusIcon}</td>
            <td>{status}</td>
            <td><RestorableLink pathname={deallink} name="deals" idx={idx}>View Details</RestorableLink></td>
            <td>{dynamicDate(requested)}</td>
            <td>{finished && dynamicDate(finished)}</td>
            <td style={{textAlign:'right'}}>{numFollowers ? numFollowers.toLocaleString('en') : '--'}</td>
            <td style={{textAlign:'right'}}>{estimatedReach ? estimatedReach.toLocaleString('en'): '--'}</td>
            <td style={{textAlign:'right'}}>{toUSD(rebate)}</td>
            <td style={{textAlign:'right'}}>{toUSD(price * 0.05)}</td>
            <td>{guruimg}</td>
            <td><RestorableLink pathname={gurulink} name="deals" idx={idx}>{handle}</RestorableLink></td>
            <td className="contain" style={{maxWidth:'100px'}}><Link to={brandLink}>{company}</Link></td>
            <td style={{textAlign:'right'}}>{toUSD(auto_tip)}</td>
            <td style={{textAlign:'right'}}>{toUSD(manual_tip)}</td>
        </tr>
    )
}
