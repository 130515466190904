import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../../../context/session/SessionContext';
import guruserver from '../../../network/guruserver'
import PaginatedTable from '../../../components/paginatedtable/PaginatedTable';
import { Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import useDefaultQuery from '../../../hooks/useDefaultQuery';
import toUSD from '../../../services/toUSD';
import { useRowRestore, RestorableLink } from '../../../hooks/restorableLinks';
import './BrandsPage.css';
import QueryParam from '../../../services/queryparam';
import { useLocation, Link } from 'react-router-dom';
import dynamicDate from '../../../services/dynamicDate';

export default function BrandsPage() {
    useDefaultQuery("?sort=-createdAt");
    const session = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(true);
    const { search } = useLocation();
    const [error, setError] = useState(null);
    const scrollRef = useRowRestore();
    const [paginatedRes, setPaginatedRes] = useState({
        results: [],
        cursorStart: 0,
        cursorEnd: 0,
        total: 0,
        page: 0,
        nPerPage: 0,
        pages: 0,
        hasNext: false,
        hasPrev: false
    })

    useEffect(() => {
        const filterQp = new QueryParam("filter", "brand")
        const req = guruserver.authorize(session).profiles();
        setIsLoading(true);
        req.search(filterQp.addTo(search)).promise()
            .then(setPaginatedRes)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session, search]);

    if (error) {
        return error.message;
    } else if (isLoading) {
        return "Loading..."
    } else return (
        <div>
            <h2>
                Brands
            </h2>
            <PaginatedTable paginatedResults={paginatedRes} striped={true} filter={[["Widget", "active", "select", [["Enabled","true"],["Disabled","false"]]]]} sort={[["-createdAt","Newest"], ["createdAt", "Oldest"], ["company", "Brand name"], ["minstagram.username","Handle"]]}>
                <thead>
                    <th>Name</th>
                    <th>Joined</th>
                    <th colspan={2}>Widget Status</th>
                    <th colspan={2}>Charge Status</th>
                    <th style={{textAlign: "right"}}>Rebate</th>
                    <th style={{textAlign: "right"}}>Limit</th>
                    <th style={{textAlign: "right"}}>Deals</th>
                    <th>Instagram</th>
                    <th>Email</th>
                </thead>
                <tbody ref={scrollRef}>
                    {paginatedRes.results.map((brand,idx) => {
                        let {
                            active,
                            minstagram: {
                                username: handle
                            },
                            createdAt,
                            chargeStatus,
                            maxRebate,
                            discount,
                            numDealsOffered,
                            email,
                            _id
                        } = brand;
                        let widgeticon;
                        if (active) {
                            widgeticon = <Icon intent={Intent.SUCCESS} icon={IconNames.FULL_CIRCLE} iconSize={12} />
                            maxRebate = (maxRebate && toUSD(maxRebate)) || null; // hide maxRebate if it's 0.
                            discount = String(Math.round(discount * 100)) + '%';
                        } else {
                            maxRebate = discount = null;
                        }

                        let chargeicon;
                        if (chargeStatus !== "Activated") {
                            const intent = chargeStatus === "Pending"
                                ? Intent.WARNING
                                : Intent.DANGER
                            chargeicon = <Icon intent={intent} icon={IconNames.FULL_CIRCLE} iconSize={12} />
                        }
                        const brandlink = (
                            <RestorableLink
                                idx={idx}
                                pathname={"/users/brands/" + brand._id}
                                name="brands"
                            >
                                {brand.company}
                            </RestorableLink>);
                        const iglink = "https://instagram.com/" + handle

                        const mailLink = <a href={`mailto:${email}`}>{email}</a>

                        const dealsDisplay = numDealsOffered > 0
                            ? <Link to={"/deals?sort=-createdAt&brand="+_id}>{numDealsOffered}</Link>
                            : numDealsOffered
                            

                        return (
                            <tr>
                                <td style={{maxWidth:'200px'}}>{brandlink}</td>
                                <td>{dynamicDate(createdAt)}</td>
                                <td>{widgeticon}</td>
                                <td className={active && "active"}>{brand.active ? "Enabled" : "Disabled"}</td>
                                <td>{chargeicon}</td>
                                <td>{brand.chargeStatus}</td>
                                <td style={{textAlign:"right"}}>{discount}</td>
                                <td style={{textAlign:"right"}}>{maxRebate}</td>
                                <td style={{textAlign:"right"}}>{dealsDisplay}</td>
                                <td><a href={iglink} target="_blank" rel="noopener noreferrer">@{handle}</a></td>
                                <td>{mailLink}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </PaginatedTable>
        </div>)
}