import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './serviceWorker';
import Service from './api_service';
import App from './App'
import './admin.css';

class AdminIndex extends React.Component {
  state = {};

  componentDidMount() {
    this.login();
  }

  render() {
    let sessionHeader;
    if (this.state.api) {
      let nameLabel;
      if (sessionStorage.name) {
        nameLabel = `Hi, ${sessionStorage.name}`;
      }
      sessionHeader = (
        <div className="dark row stretch-width admin space-between align-center">
          <p style={{marginLeft: '20px'}}>{nameLabel}</p>
          <a style={{marginRight: '20px'}} onClick={this.logout}>Logout</a>
        </div>
      );
    }

    return (
      <div>Test.</div>
    )
  }

  login = () => {
    const { admin, _id, token } = sessionStorage;
    var loggedIn = !!(admin && _id && token);

    if (loggedIn) {
      this.setState({api: new Service(_id, token)});
    }
  }

  logout = () => {
    sessionStorage.removeItem('admin');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('_id');
    sessionStorage.removeItem('name');
    this.setState({logout: true, api: null});
  }
}
ReactDOM.render(
  <App />,
  document.getElementById('root'));
//registerServiceWorker();
unregister();
export default AdminIndex;
