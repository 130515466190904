import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import StoryCard from '../storycard/StoryCard';
import './RecentlyClosed.css';
import guruserver from '../../network/guruserver';
import { SessionContext } from '../../context/session/SessionContext';
import { Link } from 'react-router-dom';

export default function RecentlyClosed() {
    const session = useContext(SessionContext);
    const [deals, setDeals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        const req = guruserver
            .authorize(session)
            .recently_closed()
        
        req
            .promise()
            .then(setDeals)
            .catch(err=>setError(err.message))
            .finally(() => setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session])

    if (error) {
        return error;
    } else if (isLoading) {
        return "Loading..."
    } else {
        return (
            <div>
                <div style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                    <h3 style={{display:"inline"}}>Recently Approved Content</h3>
                    <Link to="/deals?sort=-createdAt" style={{marginLeft:"12px"}}>View All Deals</Link>
                </div>
                <div className="recently-closed-carousel">
                    {deals.map(deal => <StoryCard deal={deal} />)}
                </div>
            </div>
        )
    }
}

RecentlyClosed.propTypes = {
    deals: PropTypes.arrayOf(StoryCard.propTypes)
}