import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {SessionContext} from '../../../context/session/SessionContext';
import guruserver from '../../../network/guruserver'
import { Card } from '@blueprintjs/core';
import './GuruPage.css';
import { useBackLink } from '../../../hooks/restorableLinks';
import dynamicDate from '../../../services/dynamicDate';

export default function GuruPage() {
    const { id } = useParams();
    const session = useContext(SessionContext);
    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const backLink = useBackLink();

    useEffect(() => {
        const req = guruserver
            .authorize(session)
            .profile(id)
            .get();

        req.promise()
            .then(setProfile)
            .catch(setError)
            .finally(()=>setIsLoading(false));

        return function cleanup() {
            req.cancel();
        }
    }, [id, session]);

    if (error) {
        return error.message
    } else if (isLoading) {
        return "Loading..."
    } else {
        const {
            _id,
            name,
            minstagram: {
                username: handle,
                followers_count
            },
            email,
            createdAt
        } = profile;

        const picLink = 'https://dr2ucvwr1tnfi.cloudfront.net/' + _id;
        const iglink = "https://instagram.com/" + handle
        const emaillink = "mailto:" + email

        return (
            <div>
                {backLink}
                <h3>Guru {_id}</h3>
                <Card className="guru-card" elevation={2}>
                    <img src={picLink} alt="" className="pro-pic-large" />
                    <div style={{display:'inline-flex',flexDirection:'column'}}>
                        <h5>{name}</h5>
                        <a href={iglink} target="_blank" rel="noopener noreferrer">@{handle}</a>
                        <a href={emaillink}>{email}</a>
                        <Link to={`/deals?sort=-createdAt&guru=${_id}`}>View deals</Link>
                        <div><h5 style={{display:"inline-block",marginRight:"12px"}}>Followers</h5>{followers_count ? followers_count.toLocaleString('en'):'--'}</div>
                        <div><h5 style={{display:"inline-block",marginRight:"12px"}}>Joined</h5>{dynamicDate(createdAt)}</div>
                    </div>
                </Card>
            </div>
        )
    }
}
