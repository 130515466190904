import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import {SessionContext} from '../../../context/session/SessionContext';
import guruserver from '../../../network/guruserver'
import { Card, Icon, Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons'
import { useBackLink } from '../../../hooks/restorableLinks';
import './DealPage.css';
import dealIntent from '../../../services/dealIntent';
import toUSD from '../../../services/toUSD';
import moment from 'moment';

export default function DealPage() {
    const { id } = useParams();
    const session = useContext(SessionContext);
    const [deal, setDeal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isRejecting, setIsRejecting] = useState(false);
    const backLink = useBackLink();

    const [jsonRequest, setJsonRequest] = useState(null);

    useEffect(() => {
        const req = guruserver
            .authorize(session)
            .deal
            .get(id);

        req.promise()
            .then(setDeal)
            .catch(setError)
            .finally(()=>setIsLoading(false));

        return function cleanup() {
            req.cancel();
        }
    }, [id, session]);

    useEffect(() => {
        if (isRejecting) {
            const req = guruserver
                .authorize(session)
                .deal
                .reject(deal._id)
                        
            req.promise()
                .then(() => setDeal({...deal, rejectedOn: Date.now(), status: "Requested"}))
                .catch(err => window.alert(err.message))
                .finally(() => setIsRejecting(false));
            
            return function cleanup() {
                req.cancel();
            }
        }
    }, [isRejecting, deal, session])

    if (error) {
        return error.message;
    } else if (isLoading) {
        return "Loading...";
    } else {
        let {
            _id,
            content,
            story,
            rebate,
            price,
            autoTip,
            tip,
            requested,
            orderedAt,
            expires,
            closed,
            status,
            influencer: {
                _id: guru_id,
                igHandle: guru_handle
            },
            brand: {
                _id: brand_id,
                company,
                minstagram: {
                    username: brand_handle
                }
            },
            notifications,
            productName,
            rejectedOn,
            stock: {
                imageUrl
            },
            numFollowers,
            estimatedReach,
            shopify_order
        } = deal;

        content = (content && content.length)
            ? content[content.length - 1]
            : null;
        const contentElem = content && (content.contentType === "image"
            ? <img className="content" src={content.sourceUrl} alt="" />
            : <video className="content" poster={content.thumbnailUrl} src={content.sourceUrl} controls/>)


        const rejectContent = async () => {
            if (window.confirm("Reject user's content?")) {
                setIsRejecting(true);
            }
        }
        return (
            <div>
                {backLink}
                <h3>Deal {_id}</h3>
                <div className="card-container">
                    <Card style={{width: '300px', height: '600px'}} elevation={2}>
                        <h4>Details</h4>
                        <div className="detail-row"><Icon icon={IconNames.SHOP} /><Link to={`/users/brands/${brand_id}`}>{company}</Link></div>
                        <div className="detail-row"><Icon icon={IconNames.USER} /><Link to={`/users/gurus/${guru_id}`}>{guru_handle}</Link></div>
                        <div className="detail-row"><Icon icon={IconNames.FULL_CIRCLE} intent={dealIntent(deal)}/><span>{(status === "Requested" && !!rejectedOn) ? "Rejected" : status}</span></div>
                        <div className="detail-row detail-row-numbers"><h5>Price</h5><span>{toUSD(price)}</span></div>
                        <div className="detail-row detail-row-numbers"><h5>Rebate</h5><span>{toUSD(rebate)}</span></div>
                        {autoTip && <div className="detail-row detail-row-numbers"><h5>Auto Tip</h5><span>{toUSD(autoTip)}</span></div>}
                        {tip && <div className="detail-row detail-row-numbers"><h5>Extra Tip</h5><span>{toUSD(tip)}</span></div>}
                        <div className="detail-row detail-row-numbers"><h5>Ordered At</h5><span>{moment(orderedAt).calendar()}</span></div>
                        <div className="detail-row detail-row-numbers"><h5>Created</h5><span>{moment(requested).calendar()}</span></div>
                        <div className="detail-row detail-row-numbers"><h5>Expires</h5><span>{moment(expires).calendar()}</span></div>
                        { closed && <div className="detail-row detail-row-numbers"><h5>Closed</h5><span>{moment(closed).calendar()}</span></div> }
                        <div className="detail-row detail-row-numbers"><h5>Num. Followers</h5><span>{(numFollowers || 0).toLocaleString('en')}</span></div>
                        <div className="detail-row detail-row-numbers"><h5>Est. Reach</h5><span>{estimatedReach.toLocaleString('en')}</span></div>
                    </Card>
                    {
                            <Card style={{width: '200px', height: '300px'}} elevation={2}>
                                <h4>Item</h4>
                                <div>
                                    <p>{productName}</p>
                                    <img src={imageUrl} alt="" style={{width: '100px', height: '100px'}}/>
                                </div>
                            </Card>
                    }
                    {
                        contentElem &&
                            <Card elevation={2} className="review-card">
                                <h4>Review</h4>
                                {contentElem}
                                {
                                    status === "Pending"
                                        ? (
                                            <Button text="Reject Content" minimal intent={Intent.DANGER} onClick={rejectContent} loading={isRejecting}/>
                                        )
                                        : status === !!rejectedOn
                                            ? "Rejected " + moment(rejectedOn).calendar()
                                            : null
                                }
                            </Card>
                    }
                    {
                        ((notifications && notifications.length) || null) &&
                            <Card elevation={2} style={{width: '800px'}}>
                                <h4>Notifications</h4>
                                <table style={{width: '100%'}} className="notifications">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Message</th>
                                            <th>Status</th>
                                            <th>Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            notifications.map(NotificationRow)
                                        }
                                    </tbody>
                                </table>
                            </Card>
                    }
                    <Card elevation={2} style={{width: '220px'}}>
                        <h4>Order ID</h4>
                        <p>{shopify_order}</p>
                        {<button onClick={()=>{setJsonRequest("order")}}>View Order</button>}
                        {<button onClick={()=>{setJsonRequest("transactions")}}>View Transactions</button>}
                    </Card>
                    <JSONCard jsonRequest={jsonRequest} deal={deal}/>
                </div>
            </div>
        )
    }
}

function JSONCard({jsonRequest, deal}) {
    const session = useContext(SessionContext);
    const [jsonResult, setJsonResult] = useState(null);
    const [jsonError, setJsonError] = useState(null);

    useEffect(() => {
        if (jsonRequest && deal) {

            const api = guruserver
                .authorize(session)
                .json

            let req;
            if (jsonRequest === "transactions") {
                req = api.transactions(deal.brand._id, deal.shopify_order)
            } else req = api.order(deal.brand._id, deal.shopify_order);

            req.promise()
                .then(setJsonResult)
                .catch(err=>setJsonError(err.message))

            return function cleanup() {
                req.cancel();
            }
        }
    }, [session, jsonRequest, deal])
    if (!jsonResult) {
        return null;
    } else if (jsonError) {
        return jsonError.message;
    } else if (!jsonResult) {
        return "Loading...";
    } else {
        return (
            <div style={{backgroundColor:"#ffb0b0"}}>
                <pre>{JSON.stringify(jsonResult, undefined, 2)}</pre>
            </div>
        )
    }
}

function NotificationRow(notif) {
    const { 
        createdOn,
        message,
        errorCount,
        notified
    } = notif;
    return (
        <tr>
            <td>{moment(createdOn).calendar()}</td>
            <td>{message}</td>
            <td>{notified > 0 ? "Success" : "Error"}</td>
            <td>{errorCount ? "Error" : ""}</td>
        </tr>
    )
}