import { Intent } from '@blueprintjs/core';

/**
 * For color-coded icons indicating a deal's current status.
 * instead of DEFAULT for "Requested", will return null to easily inform
 * user that omitting the status indicator altogether might be a better choice
 * @param {Deal} deal 
 */
export default function dealIntent(deal) {
    switch (deal.status) {
        case "Pending":
            return Intent.PRIMARY;
        case "Done":
            return Intent.SUCCESS;
        case "Expired":
            return Intent.DANGER;
        case "Paused":
            return Intent.WARNING;
        default:
            return deal.rejectedOn
                ? Intent.WARNING
                : null;
    }
}