import React from 'react';
import { Chart } from 'react-charts';

export default function ActiveStoresChart({metrics, ...props}) {
    const data = React.useMemo(
        () => [
            {
                label: 'Stores',
                data: metrics.map(dayStats =>[
                    new Date(dayStats.day_start), dayStats.active_stores
                ])
            }
        ],
        [metrics]
    )

    const axes = React.useMemo(
        () => [
            { primary: true, type: 'time', position: 'bottom' },
            { type: 'linear', position: 'left' }
        ],
        []
    )

    return (
        <div {...props}>
            <h3>Stores</h3>
            <div
                style={{
                    width: '600px',
                    height: '440px'
                }}
            >
                <Chart
                    data={data}
                    axes={axes}
                    tooltip
                />
            </div>
        </div>
    )
}