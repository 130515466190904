import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../../../context/session/SessionContext'
import guruserver from '../../../network/guruserver';
import { EditableText, Button, HTMLSelect } from '@blueprintjs/core';
import { useLocation } from 'react-router-dom';
import PaginatedTable from '../../../components/paginatedtable/PaginatedTable';
import moment from 'moment';

export default function SignUpSessionsTable() {
    const [activeRow, setActiveRow] = useState(null);
    const [followers, setFollowers] = useState("");
    const [follows, setFollows] = useState("");
    const [ig_id, setIg_id] = useState("");
    const [denialReason, setDenialReason] = useState("");
    const [isResponding, setIsResponding] = useState(false); // responding = loading
    const [req, setReq] = useState(null);
    const session = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { search } = useLocation();

    // only enable admit button if the profile fields are set,
    // to reduce accidental clicking
    const admitEnabled = !!(follows && followers && ig_id && !isResponding);

    // only enable denying if a reason for denying has been set,
    // to reduce accidental clicking
    const denyEnabled = !!(denialReason && !isResponding)

    const [paginatedRes, setPaginatedRes] = useState({
        results: [],
        cursorStart: 0,
        cursorEnd: 0,
        total: 0,
        page: 0,
        nPerPage: 0,
        pages: 0,
        hasNext: false,
        hasPrev: false
    });

    useEffect(() => {
        const req = guruserver.authorize(session).scraper.signupsessions()
        setIsLoading(true);
        req.search(search).promise()
            .then(setPaginatedRes)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [ session, search]);


    const respondToSignUp = async ( action) => {
        try {
            setIsResponding(true);
            const _req = guruserver
                .authorize(session)
                .scraper
                .respondToSignUp(
                    paginatedRes.results[activeRow]._id,
                    ig_id, action, denialReason, followers, follows
                )
            setReq(_req);
            await _req.promise();
            const sessionsCopy = Array.from(paginatedRes.results);
            sessionsCopy.splice(activeRow, 1);
            setPaginatedRes({
                ...paginatedRes,
                results: sessionsCopy
            });
            setActiveRow(null); setFollowers(""); setFollows("");
            setIg_id(""); setDenialReason("");
        } catch (err) {
            window.alert(err.message);
        } finally {
            setIsResponding(false);
        }
    }
    useEffect(() => {
        if (req) {
            return function cleanup() {
                req.cancel();
            }
        }
    }, [req])

    if (isLoading) {
        return "Loading...";
    } else if (error) {
        return error.message;
    } else {

        return (
            <PaginatedTable paginatedResults={paginatedRes} striped={true}>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Attempted At</th>
                        <th>Comment</th>
                        <th>Post</th>
                        <th>Followers</th>
                        <th>Follows</th>
                        <th>id</th>
                        <th>Admit</th>
                        <th colSpan={2}>Deny</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedRes.results.map((session,idx) => {
                        const {
                            _id,
                            ig_handle,
                            email,
                            attemptedFinishAt,
                            post_shortcode,
                            comment
                        } = session;

                        const handlelink = <a
                            href={`https://instagram.com/${ig_handle}?__a=1`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={()=>setActiveRow(idx)}
                        >{ig_handle}</a>

                        const shortcodelink = <a
                            href={`https://instagram.com/p/${post_shortcode}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{post_shortcode}</a>

                        return (
                            <tr key={idx}>
                                <td>{handlelink}</td>
                                <td>{moment(attemptedFinishAt).fromNow()}</td>
                                <td>{comment}</td>
                                <td>{shortcodelink}</td>
                                <td>{activeRow === idx && <EditableText disabled={isResponding} value={followers} onChange={setFollowers}/>}</td>
                                <td>{activeRow === idx && <EditableText disabled={isResponding} value={follows} onChange={setFollows}/>}</td>
                                <td>{activeRow === idx && <EditableText disabled={isResponding} value={ig_id} onChange={setIg_id}/>}</td>
                                <td><Button loading={isResponding} onClick={()=>respondToSignUp("approve")} text="Admit" minimal disabled={!admitEnabled}/></td>
                                <td>{activeRow === idx && <HTMLSelect disabled={isResponding} value={denialReason} onChange={e=>setDenialReason(e.target.value)} options={denialReasons}/>}</td>
                                <td><Button loading={isResponding} onClick={()=>respondToSignUp("deny")} text="Deny" minimal disabled={!denyEnabled} /></td>
                            </tr>
                        )
                    })}
                </tbody>
            </PaginatedTable>
        )
    }
}
const denialReasons = [{value: ""},{label:"User Private",value:"userprivate"}, {label:"No Comment", value:"commentnotfound"}, {label:"Duplicate Instagram", value: "duplicateinstagram"}]