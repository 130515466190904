import encrypt from './jwefunctions';

const sandbox_url = 'https://8qhscmink4.execute-api.us-east-1.amazonaws.com/staging/';
const live_url = 'https://8qhscmink4.execute-api.us-east-1.amazonaws.com/2020-02/';

/**
 * 
 * @param {String} userid User doc mongo _id
 * @param {String} token Token string returned by server
 * @param {String} mode 'live' or 'sandbox'. Default 'live'.
 * @param {String} externalreference ext ref header returned by server (when using plugin)
 */
function Service(userid, token, externalreference, mode = 'live') {
  this.userid = userid;
  this.externalreference = externalreference;

  if (/^(https:\/\/\w+\.ngrok)|(http:\/\/localhost)/.test(window.location.origin)) mode = 'sandbox';

  // most calls will use `default_headers`.. `plugin_headers` might be used for any call
  // which requires authentication when potentially no user is yet linked
  const default_headers = {
    authtoken: token,
    mode,
    userid
  }

  this.plugin_headers = Object.assign({}, default_headers);
  Object.assign(this.plugin_headers, {
    userid: 'none',
    externalreference
  });

  this.api = require('axios').create({
    baseURL: mode === 'sandbox' ? sandbox_url : live_url,
    headers: default_headers
  });
}

function unsafeValidation(response) {
  if (!response || !response.data) {
    throw new Error("Invalid response.");
  } else if (response.data.status !== 'SUCCESS') {
    throw new Error(response.data.message || "No error message given.");
  } else {
    return response.data.message;
  }
}

function standardValidate(response) {
  if (!response.data.status || response.data.status !== 'SUCCESS') throw new Error(response.data.message || "Unknown error.");
  return response;
}

function validateIsArray(response) {
  if (!Array.isArray(response.data.message)) throw new Error("Response data is not an array.");
  return response.data.message;
}

/**
 * Makes an authorization attempt, meaning that query params are sent to guru.club server
 * and checked for valid values (e.g. shop matches a valid nonce value).
 * 
 * Unsafely validated, meaning if there is a bad response from server an error will be thrown.
 * 
 * @param queryParams {Object} A dictionary of query parameters, taken from window url
 * @param return_url {String} Used when user needs to authorize a recurring charge. Shopify stores this and redirects the user to this value upon accepting the charge.
 */
Service.prototype.AuthorizeShopify = async function(queryParams, return_url) {
  return unsafeValidation(
    await this.api.post('shopify/authorize', Object.assign(queryParams, {return_url})));
}

/**
 * Attempts to activate a charge with Shopify with the given charge id
 * 
 * Unsafely validated, meaning if there is a bad response from server an error will be thrown.
 * 
 * @param charge_id {String} The charge id given by Shopify
 * @returns {Promise<{shop: {Object}}>} Promise'd object which can be destructured for Shopify Shop object.
 */
Service.prototype.ActivateCharge = async function(charge_id) {
  return unsafeValidation(
    await this.api.post(`shopify/activate?charge_id=${charge_id}`));
}

Service.prototype.ToggleAutoTip = async function() {
  return standardValidate(await this.api.get(`${this.userid}/toggletips`)).data.message;
  // ^ returns new value for "profile.autoTips"
}

Service.prototype.MyDeals = async function() {
  const response = await this.api.get(this.userid + '/viewmydeals');
  if (!response.data.message.current) throw new Error("Unable to fetch deals.");
  return response.data.message;
}

Service.prototype.CheckTokenIsValid = async function(token) {
  return standardValidate(await this.api.get('checknonce?token=' + token));
}

Service.prototype.LostPassword = async function(email) {
  return standardValidate(await this.api.get('lostpass?email=' + email));
}

Service.prototype.ChangePassword = async function(opts) {
  const { new_pass, token, email } = opts;
  const encryptedBody = await encrypt(JSON.stringify({
    password: new_pass
  }));
  return standardValidate(await this.api.post('newpass?token=' + token + '&email=' + email, {
    jwe: encryptedBody
  }));
}

Service.prototype.MyProducts = async function() {
  return validateIsArray(await this.api.get(this.userid + '/products'));
}

Service.prototype.GetTips = async function() {
  const response = await this.api.get(this.userid + '/tips');
  return validateIsArray(response);
}

Service.prototype.SetTip = async function(deal_id, amount) {
  await this.api.post(this.userid + '/tip', {
    deal: deal_id,
    tip: amount
  });
}

Service.prototype.InstallShopify = async function(queryParams, return_url) {
  return unsafeValidation(await this.api.post('shopify/install',
    Object.assign(queryParams, {return_url})));
}

Service.prototype.Tip = function(product_id, id_array) {
  return this.api.post(this.userid + '/tips/' + product_id, id_array);
}

Service.prototype.Login = async function(login_body) {
  const encryptedBody = await encrypt(JSON.stringify(login_body));
  return await this.api.post('3/login', { jwe: encryptedBody });
}

Service.prototype.GetAttachedProfile = async function() {
  const [plugin_name, shop_id] = this.externalreference.split(':');
  return standardValidate(await this.api.get('/plugins/' + plugin_name + '/' + shop_id + '/account', {
    headers: this.plugin_headers
  }));
}

Service.prototype.LinkShop = function(mode, body, shop_id) {
  return this.api.post('/plugins/shopify/' + shop_id + '/link?mode=' + mode, body, {
    headers: this.plugin_headers
  });
}

Service.prototype.CreateAccount = async function(email, handle, shop_id) {
  return standardValidate(await this.api.post('/plugins/shopify/' + shop_id + '/createaccount?email=' + email + '&handle=' + handle, { }, {
    headers: this.plugin_headers
  }))
}

Service.prototype.UsageCharges = async function(followingID) {
  let path = "admin/" + this.userid + "/usagecharges";
  if (followingID) path += "?followingID=" + followingID;

  return standardValidate(await this.api.get(path));
}

Service.prototype.Deals = async function(followingID) {
  let path = "admin/" + this.userid + "/deals";
  if (followingID) path += "?followingID=" + followingID;

  return standardValidate(await this.api.get(path));
}

Service.prototype.GetProfile = function(profile_id) {
  return this.api.get('admin/' + this.userid + '/profile/' + profile_id);
}

Service.prototype.GetNotifications = async function() {
  return validateIsArray(await this.api.get('admin/' + this.userid + '/notifications'));
}

Service.prototype.GetKPIs = async function() {
  return validateIsArray(await this.api.get('admin/' + this.userid + '/kpis'))
}

Service.prototype.SendNotification = async function(message) {
  const response = standardValidate(await this.api.post('admin/' + this.userid + '/notification', { message: message }));
  return response.data.message;
}

Service.prototype.GetProfiles = function(filter, following_id) {
  var queryString = '?filter=' + filter;
  if (following_id) {
    queryString += '&following_id=' + following_id;
  }
  return this.api.get('admin/' + this.userid + '/allprofiles' + queryString);
}

Service.prototype.AdminProducts = async function(brand_id) {
  return validateIsArray(await this.api.get('admin/' + this.userid + '/products/' + brand_id));
}

Service.prototype.SetProductSuspended = async function(product_id, isSuspended) {
  const status = isSuspended ? "suspended" : "unsuspended";
  return standardValidate(await this.api.put('admin/' + this.userid + '/setproductsuspended/' + product_id + '?status=' + status));
}

Service.prototype.UnlinkShop = function() {
  return this.api.get(this.userid + '/shopify/unlink');
}

Service.prototype.Apply = function(application) {
  return this.api.post('3/apply', application);
}

Service.prototype.UpdateProfile = async function(body) {
  const baseBody = { _id: this.userid, kind: "brand" };
  return standardValidate(await this.api.put(this.userid + '/profile', Object.assign(baseBody, body)));
}

/**
 * active: Boolean
 * pages: [String]
 */
Service.prototype.SetActive = async function(active, pages) {
  const action = active ? "activate" : "deactivate";
  pages = pages && pages.join(",")
  return standardValidate(await this.api.post(this.userid + `/shopify/script_tag?action=${action}&pages=${pages}`));
}

Service.prototype.ReloadProfile = function() {
  return this.api.get(`${this.userid}/profile?id=${this.userid}`);
}

Service.prototype.UpdateProduct = async function(body) {
  return standardValidate(await this.api.put(this.userid + '/product', body));
}

Service.prototype.BulkUpdate = async function(products, rebate) {
  return standardValidate(await this.api.put(this.userid
    + '/products', { products, rebate }));
}

Service.prototype.UpdateTips = async function(tips_body) {
  return standardValidate(await this.api.put(this.userid + '/tips', tips_body));
}

Service.prototype.UploadFile = async function(file, endpoint) {
  const formData = new FormData();
  formData.append('file', file);
  return standardValidate(await this.api.post(endpoint, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      'accept': 'application/json'
    }
  }));
}

Service.prototype.UploadImage = async function(file, for_user, type = 'logo') {
  var endpoint = 'admin/' + this.userid;
  switch(type) {
    case 'logo':
      endpoint += '/profile-picture/';
      break;
    case 'banner':
      endpoint += '/background-picture/';
      break;
    default:
      throw new Error("Invalid type parameter sent to Upload: " + type);
  }
  endpoint += for_user;
  return await this.UploadFile(file, endpoint);
}

Service.prototype.CheckForPicture = async function(url) {
  try {
    await this.api.head(url);
    return true;
  } catch (err) {
    return false;
  }

}

Service.prototype.RespondToApplication = async function(profile_id, action) {
  return standardValidate(await this.api.put('admin/' + this.userid + '/respond/' + profile_id + '?action=' + action));
}

Service.prototype.AdminDeleteProfile = async function(profile_id) {
  return standardValidate(await this.api.delete('admin/' + this.userid + '/profile/' + profile_id));
}

Service.prototype.AdminUpdateProfile = async function(profile_id, update) {
  return standardValidate(await this.api.put('admin/' + this.userid + '/profile/' + profile_id, update));
}

Service.prototype.Ledger = async function(profile_id) {
  return validateIsArray(await this.api.get('admin/' + this.userid + '/ledger/' + profile_id));
}

Service.prototype.RejectDeal = async function(deal_id) {
  return standardValidate(await this.api.get('admin/' + this.userid + '/reject/' + deal_id));
}

export default Service;

export const Default = new Service();
