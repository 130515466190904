const api_stage = process.env.REACT_APP_API_STAGE || "2020-02";
const mode = process.env.REACT_APP_MODE || 'live';

const api = (() => {
    const axios = require('axios');
    const { CancelToken } = axios;    
    const instance = axios.create({
        baseURL: "https://8qhscmink4.execute-api.us-east-1.amazonaws.com/" + api_stage
    });

    return {
        request: (config) => {
            const source = CancelToken.source();

            const promise = function() {
                return new Promise((resolve, reject) => {
                instance.request({...config, cancelToken: source.token})
                    .then(res => {
                        if (res.data.status === "SUCCESS" || res.data.status === "PENDING") {
                            resolve(res.data.message);
                        } else if (res.status === 401) {
                            reject(new Error("Unauthorized"));
                        } else { reject(new Error(res.data.message)) }
                    })
                    .catch(err => {
                        if (axios.isCancel(err)) {
                            console.log("Request cancelled", err.message)
                        } else {
                            reject(err);
                        }
                    })
                });
            }
            
            return {
                cancel: (msg) => source.cancel(msg),
                promise,
                search: function(searchstring) {
                    let searchStart = config.url.indexOf("?");
                    config.url = searchStart === -1
                        ? config.url + searchstring
                        : config.url.substring(0, searchStart) + searchstring
                    return this;
                }
            }
        }
    }
})();

export default {
    login: function (jweString) {
        return api.request({
            method: 'post',
            url: '/3/login',
            data: { jwe: jweString }
        });
    },

    authorize: function ({userID, authToken}) {
        const authHeaders = {
            mode,
            userid: userID,
            authtoken: authToken
        };

        return {

            dashboardstats: function() {
                return api.request({
                    url: `/admin/${userID}/dashboardstats`,
                    headers: authHeaders,
                    method: 'get'
                })
            },

            recently_closed: function() {
                return api.request({
                    url: `/admin/${userID}/recently_closed`,
                    headers: authHeaders,
                    method: 'get'
                })
            },

            /**
             * 
             * @param {"guru"|"brand"} filter 
             */
            profiles: function () {
                return api.request({
                    url: `/admin/${userID}/allProfiles`,
                    headers: authHeaders,
                    method: 'get'
                })
            },

            profile: function(id) {
                return {
                    get: function() {
                        return api.request({
                            url: `/admin/${userID}/profile/${id}`,
                            headers: authHeaders,
                            method: 'get'
                        })
                    },

                    put: function(update) {
                        return api.request({
                            url: `/admin/${userID}/profile/${id}`,
                            headers: authHeaders,
                            method: 'put',
                            data: update
                        })
                    },

                    /**
                     * 
                     * @param {Boolean} active
                     * @param {string[]} pages Any combination/ordering of ["order","product","cart"]
                     */
                    updateScriptTags: function(active, pages) {
                        const action = active ? "activate" : "deactivate"
                        return api.request({
                            url: `/admin/${userID}/profile/${id}/shopify?action=${action}&pages=${pages.join(',')}`,
                            headers: authHeaders,
                            method: 'put',
                        })
                    }
                }
            },

            deals: function() {
                return api.request({
                    url: `/admin/${userID}/deals`,
                    headers: authHeaders,
                    method: 'get'
                });
            },

            deal: {
                get: function(id) {
                    return api.request({
                        url: `/admin/${userID}/deal/${id}`,
                        headers: authHeaders,
                        method: 'get'
                    })
                },
                
                reject: function(id) {
                    return api.request({
                        url: `/admin/${userID}/reject/${id}`,
                        headers: authHeaders,
                        method: 'get'
                    })
                }
            },

            stories: function() {
                return api.request({
                    url: `/admin/${userID}/stories`,
                    headers: authHeaders,
                    method: 'get'
                })
            },

            json: {
                order: function(brandid, orderid) {
                    return api.request({
                        url: `/admin/${userID}/profile/${brandid}/order/${orderid}/order.json`,
                        headers: authHeaders,
                        method: 'get'
                    })
                },

                transactions: function(brandid, orderid) {
                    return api.request({
                        url: `/admin/${userID}/profile/${brandid}/order/${orderid}/transactions.json`,
                        headers: authHeaders,
                        method: 'get'
                    })
                }
            },

            scraper: {
                status: function() {
                    return api.request({
                        url: `/admin/${userID}/scraperstatus`,
                        headers: authHeaders,
                        method: 'get'
                    })
                },
                signupsessions: function() {
                    return api.request({
                        url: `/admin/${userID}/signupsessions`,
                        headers: authHeaders,
                        method: 'get'
                    })
                },
                storyjobs: function() {
                    return api.request({
                        url: `/admin/${userID}/storyjobs`,
                        headers: authHeaders,
                        method: 'get'
                    })
                },
                respondToSignUp: function(sessionid, igid, action, denialReason, followers_count, follows_count) {
                    return api.request({
                        method: 'post',
                        headers: authHeaders,
                        url: `/admin/${userID}/respond?id=${sessionid}&instagramid=${igid}&action=${action}&reason=${denialReason}&followers_count=${followers_count}&follows_count=${follows_count}`
                    })
                },
                mockStory: function(deal_id, story) {
                    return api.request({
                        method: 'post',
                        headers: authHeaders,
                        url: `/admin/${userID}/mockScraperStory`,
                        data: {
                            deal_id,
                            story
                        }
                    })
                },
                mockStoryStillUp: function(deal_id, stillUp) {
                    return api.request({
                        method: 'post',
                        headers: authHeaders,
                        url: `/admin/${userID}/mockScraperFoundStory?deal_id=${deal_id}&found=${stillUp}`
                    })
                }
            }
        }
    }
}