export default class QueryParam {
    constructor(key, value) {
        this.key = key;
        this.value = value;
        this.paramStr = `${key}=${value}`;
    }

    isActiveIn(search) {
        return search.includes(this.paramStr);
    }

    removeFrom(search) {
        return search.replace(new RegExp("(\\?|\\&)" + this.paramStr), "");
    }

    addTo(search) {
        return search.includes(`${this.key}=`)
            ? search.replace(new RegExp(`${this.key}=([^&]*)`), this.paramStr)
            : search.includes('?')
                ? search + "&" + this.paramStr
                : search + "?" + this.paramStr
    }
}