import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../../../context/session/SessionContext'
import guruserver from '../../../network/guruserver';
import { HTMLTable, EditableText, Button, HTMLSelect } from '@blueprintjs/core';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import PaginatedTable from '../../../components/paginatedtable/PaginatedTable';

export default function StoryJobsTable() {
    const [activeRow, setActiveRow] = useState(null);
    const [ig_id, setIg_id] = useState("");
    const [timestamp, setTimestamp] = useState("");
    const [expiringTimestamp, setExpiringTimestamp] = useState("");
    const [media_url, setMedia_url] = useState("");
    const [thumbnail_url, setThumbnail_url] = useState("");
    const [foundVal, setFoundVal] = useState(null);
    const [isMocking, setIsMocking] = useState(false); // mocking = loading
    const session = useContext(SessionContext);
    const [req, setReq] = useState(null);
    const { search } = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const [paginatedRes, setPaginatedRes] = useState({
        results: [],
        cursorStart: 0,
        cursorEnd: 0,
        total: 0,
        page: 0,
        nPerPage: 0,
        pages: 0,
        hasNext: false,
        hasPrev: false
    })

    useEffect(() => {
        const req = guruserver.authorize(session).scraper.storyjobs()
        setIsLoading(true);
        req.search(search).promise()
            .then(setPaginatedRes)
            .catch(setError)
            .finally(()=>setIsLoading(false))

        return function cleanup() {
            req.cancel();
        }
    }, [session, search]);

    const mockStory = async () => {
        const deal_id = paginatedRes.results[activeRow].deal._id;
        try {
            if (foundVal == null) throw new Error("Invalid value for found");
            const story = foundVal === "true" // multiply timestamps by 1000, as IG stores seconds instead of ms
                ? { taken_at_timestamp:Number(timestamp)*1000,ig_id,media_url,expiring_at_timestamp:Number(expiringTimestamp)*1000, thumbnail_url }
                : null;
            setIsMocking(true);
            const _req = guruserver
                .authorize(session)
                .scraper
                .mockStory(deal_id, story);
            setReq(_req);
            await _req.promise();
            const jobsCopy = Array.from(paginatedRes.results);
            jobsCopy[activeRow].scraperresponse = {createdAt: new Date() };
            setPaginatedRes({
                ...paginatedRes,
                results: jobsCopy
            });
            setActiveRow(null); setTimestamp(""); setMedia_url("");
            setIg_id(""); setFoundVal("");
        } catch (err) {
            window.alert(err.message);
        } finally {
            setIsMocking(false);
        }
    }

    const mockStoryFound = async () => {
        const deal_id = paginatedRes.results[activeRow].deal._id;
        try {
            if (foundVal == null) throw new Error("Invalid value for found");
            setIsMocking(true);
            const _req = guruserver
                .authorize(session)
                .scraper
                .mockStoryStillUp(deal_id, foundVal === "true");

            // for cancelling the request
            setReq(_req);

            await _req.promise();
            const jobsCopy = Array.from(paginatedRes.results);
            jobsCopy[activeRow].scraperresponse = {createdAt: new Date() };
            setPaginatedRes({
                ...paginatedRes,
                results: jobsCopy
            });
            setActiveRow(null); setFoundVal(null);
        } catch (err) {
            window.alert(err.message);
        } finally {
            setIsMocking(false);
        }
    }

    useEffect(() => {
        if (req) {
            return function cleanup() {
                req.cancel();
            }
        }
    }, [req])

    if (isLoading) {
        return "Loading...";
    } else if (error) {
        return error.message;
    } else {

        return (
            <PaginatedTable paginatedResults={paginatedRes} striped={true}>
                <thead>
                    <tr>
                        <th>Last Run</th>
                        <th>Next Run</th>
                        <th>Handled</th>
                        <th>guru</th>
                        <th>Story</th>
                        <th>brand</th>
                        <th>ig_id</th>
                        <th>Taken At</th>
                        <th>Expires At</th>
                        <th>media url</th>
                        <th>thumbnail url</th>
                        <th colSpan={2}>Mock</th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody>
                {paginatedRes.results.map((storyjob, idx) => {
                        const {
                            deal: {
                                _id,
                                influencer: {
                                    minstagram: {
                                        id: user_ig_id,
                                        username: guru_handle
                                    }
                                }, brand: {
                                    minstagram: {
                                        username: brand_handle
                                    }
                                },
                                status,
                                story,
                            },
                            lastRunAt,
                            nextRunAt,
                            failedAt,
                            scraperresponse
                        } = storyjob;
                        const responseTime = scraperresponse && scraperresponse.createdAt;
                        const profilelink = 'https://instagram.com/' + guru_handle;
                        const jsonlink    = 'https://instagram.com/graphql/query/?query_hash=f5dc1457da7a4d3f88762dae127e0238&variables=%7B%22reel_ids%22%3A%5B%22' + user_ig_id + '%22%5D%2C%22tag_names%22%3A%5B%5D%2C%22location_ids%22%3A%5B%5D%2C%22highlight_reel_ids%22%3A%5B%5D%2C%22precomposed_overlay%22%3Afalse%2C%22show_story_viewer_list%22%3Atrue%2C%22story_viewer_fetch_count%22%3A50%2C%22story_viewer_cursor%22%3A%22%22%2C%22stories_video_dash_manifest%22%3Afalse%7D'
                        const foundStoryEnabled = !!(ig_id && timestamp && expiringTimestamp && media_url)
                        const style = failedAt
                            ? { backgroundColor: '#faa' }
                            : responseTime
                                && new Date(responseTime) > new Date(lastRunAt)
                                && new Date(responseTime) < new Date(nextRunAt)
                                ? { backgroundColor: '#afa' }
                                : {}
                        const setRowActive = () => setActiveRow(idx)
                        if (status === "Pending") {
                            const {
                                media_url,
                                thumbnail_url
                            } = story;
                            return (
                                <tr style={style} key={_id}>
                                    <td>{lastRunAt && moment(lastRunAt).fromNow()}</td>
                                    <td>{nextRunAt && moment(nextRunAt).fromNow()}</td>
                                    <td>{responseTime && moment(responseTime).calendar()}</td>
                                    <td><a href={profilelink} target="_blank" rel="noopener noreferrer">{guru_handle}</a></td>
                                    <td><a onClick={setRowActive} href={jsonlink} target="_blank" rel="noopener noreferrer">JSON</a></td>
                                    <td>@{brand_handle}</td>
                                    <td>{story.ig_id}</td>
                                    <td>{moment(story.taken_at_timestamp).calendar()}</td>
                                    <td>{moment(story.expiring_at_timestamp).calendar()}</td>
                                    <td style={{maxWidth:'120px'}}><a href={media_url} target="_blank" rel="noopener noreferrer">View</a></td>
                                    <td style={{maxWidth:'120px'}}><a href={thumbnail_url} target="_blank" rel="noopener noreferrer">View</a></td>
                                    <td style={{minWidth:'120px'}}>{ activeRow === idx && <HTMLSelect value={foundVal} onChange={e=>setFoundVal(e.target.value)} options={[{label:"",value:null},{label:"Story Found",value:true},{label:"Story Missing",value:false}]} />}</td>
                                    <td>{ activeRow === idx && <Button text="Save" onClick={mockStoryFound} disabled={foundVal == null || isMocking} />}</td>
                                    <td>{status}</td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr style={style} key={_id}>
                                    <td>{lastRunAt && moment(lastRunAt).fromNow()}</td>
                                    <td>{nextRunAt && moment(nextRunAt).fromNow()}</td>
                                    <td>{responseTime && moment(responseTime).calendar()}</td>
                                    <td><a href={profilelink} target="_blank" rel="noopener noreferrer">{guru_handle}</a></td>
                                    <td><a onClick={()=>setActiveRow(idx)} href={jsonlink} target="_blank" rel="noopener noreferrer">JSON</a></td>
                                    <td>@{brand_handle}</td>
                                    <td>{activeRow === idx && <EditableText value={ig_id} onChange={setIg_id} />}</td>
                                    <td>{activeRow === idx && <EditableText value={timestamp} onChange={setTimestamp} />}</td>
                                    <td>{activeRow === idx && <EditableText value={expiringTimestamp} onChange={setExpiringTimestamp} />}</td>
                                    <td style={{maxWidth:'120px'}}>{activeRow === idx && <EditableText value={media_url} onChange={setMedia_url} />}</td>
                                    <td style={{maxWidth:'120px'}}>{activeRow === idx && <EditableText value={thumbnail_url} onChange={setThumbnail_url} />}</td>
                                    <td style={{minWidth:'120px'}}>{activeRow === idx && <HTMLSelect value={foundVal} onChange={e=>setFoundVal(e.target.value)} options={[{value:null,label:""},{label:"Not Found", value: false},{value: true, label:"Found",disabled:!foundStoryEnabled}]} />}</td>
                                    <td>{ activeRow === idx && <Button text="Save" onClick={mockStory} disabled={foundVal == null || isMocking} />}</td>
                                    <td>{status}</td>
                                </tr>
                            )
                        }
                })} 
                </tbody>
            </PaginatedTable>
        );
    }
}
