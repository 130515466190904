import React from 'react';
import { Card, Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './KPICard.css';
import { IconNames } from '@blueprintjs/icons';
import toUSD from '../../services/toUSD';

export default function KPICard({children}) {
    const width = children.length * 190 + 40;
    return (
        <Card elevation={2} className="kpi-card" style={{width: `${width}px`}}>
            {children.map(KPIEntry)}
        </Card>
    )
}

function KPIEntry({total, label, newToday, isMoney}) {
    let newTodayIcon; let newTodayColor;
    if (newToday) {
        if (newToday > 0) {
            newTodayIcon = IconNames.ARROW_UP;
            newTodayColor = "#4B7902";
        } else {
            newTodayIcon = IconNames.ARROW_DOWN;
            newTodayColor = "#c23030";
        }
        newToday = Math.abs(newToday);
    }

    const display = val => isMoney ? toUSD(val) : val.toLocaleString('en')

    return (
        <div className="kpi-entry">
            <p>{display(total)}</p>
            <p>{label.toLocaleString('en')}</p>
            {(newToday || null) && <p style={{color: newTodayColor}}><Icon icon={newTodayIcon} /> {display(newToday)} Today</p>}
        </div>
    )
}

KPIEntry.propTypes = {
    total: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    newToday: PropTypes.number,
    isMoney: PropTypes.bool
}

KPICard.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape(KPIEntry.propTypes))
}