import React from 'react';
import { Redirect } from 'react-router-dom';

function PrivateContent(props) {
    const { isAuthenticated, redirect, children } = props;

    if (!isAuthenticated) {
        return <Redirect to={redirect} />
    } else {
        return children;
    }
}

export default PrivateContent